<template>
  <div>
    <div v-if="users.data && users.data.length" class="position-table">
      <md-table
        v-model="users.data"
        :md-sort-fn="customSort"
        :md-sort.sync="customSortBy"
        :md-sort-order.sync="customSortOrder"
      >
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          @click="$router.push(`/users/${item.id}`)"
        >
          <md-table-cell md-label="ID" md-sort-by="id" class="custom-field">
            <span>{{ item.id }}</span>
          </md-table-cell>

          <md-table-cell md-label="ФИО" md-sort-by="name" class="custom-field">
            <span>{{ item.name }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Номер телефона"
            md-sort-by="phone"
            class="custom-field"
          >
            <span>{{ item.phone }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="E-mail"
            md-sort-by="email"
            class="custom-field"
            v-if="item.order"
          >
            <span>{{ item.email }}</span>
          </md-table-cell>

          <md-table-cell md-label="Заказов" class="custom-field">
            <span>{{ item.orders_count }}</span>
          </md-table-cell>

          <md-table-cell md-label="Оборот" class="custom-field">
            <span>{{ item.orders_sum_price }}</span>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="users.meta.last_page > 1" class="pagination">
        <md-button
          class="md-primary"
          :disabled="users.meta.current_page === 1"
          @click="$emit('prev')"
        >
          <md-icon>navigate_before</md-icon>
        </md-button>
        <div class="pages">
          <span>{{ users.meta.current_page }}</span>
          /
          <span>{{ users.meta.last_page }}</span>
        </div>
        <md-button
          class="md-primary"
          :disabled="users.meta.current_page === users.meta.last_page"
          @click="$emit('next')"
        >
          <md-icon>navigate_next</md-icon>
        </md-button>
      </div>
    </div>
    <div v-else class="my-empty empty">Пусто</div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      customSortBy: "id",
      customSortOrder: "asc",
    };
  },

  computed: {
    ...mapState({
      users: (state) => state.users.users,
    }),
  },

  methods: {
    customSort(value) {
      this.$emit("sort", {
        sortBy: this.customSortBy,
        sortOrder: this.customSortOrder,
      });

      return value;
    },

    getStatus(status) {
      switch (status) {
        case 1:
        case "active":
          return "Активен";
        case 2:
        case "not_verified":
          return "Не подтвержден";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;

  .pages {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
}

::v-deep {
  .md-table-sortable-icon {
    left: unset !important;
    right: 8px !important;
  }

  .md-table-row {
    cursor: pointer;
  }
}
</style>
